<template>
<div>
    <section id="knowledge-base-content">
        <!-- content -->
        <b-row class="kb-search-content-info match-height">
            <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/sales.1172632b.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-videos-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Videolar</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders videoları
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/marketing.2ae684bb.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-documents-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Dökümanlar</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders dökümanları(*.pdf)
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/sales.1172632b.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-exams-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Konu Testleri</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders testleri
                    </b-card-text>
                </b-card>
            </b-col>
            <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/marketing.2ae684bb.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-gains-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Kazanımlar</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders Kazanımları
                    </b-card-text>
                </b-card>
            </b-col>
            <!-- <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/sales.1172632b.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-surveys-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Anketler</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders anketleri
                    </b-card-text>
                </b-card>
            </b-col> -->
            <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/marketing.2ae684bb.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-homeworks-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Ev Ödevleri</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders ev ödevleri
                    </b-card-text>
                </b-card>
            </b-col>
            <!-- <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/sales.1172632b.svg" img-alt="Test" img-top @click="$router.push({ name: 'pages-knowledge-base-category', params: { category: item.category } })">
                    <h4>Embed Ekleme</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders embed Ekleme
                    </b-card-text>
                </b-card>
            </b-col> -->
            <!-- <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/marketing.2ae684bb.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-liveLessons-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Canlı Dersler</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders canlı dersleri
                    </b-card-text>
                </b-card>
            </b-col> -->
            <b-col md="4" sm="6" class="kb-search-content">
                <b-card class="text-center cursor-pointer" img-src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/marketing.2ae684bb.svg" img-alt="Test" img-top @click="$router.push({ name: 'teacher-pages-page', params: { videoGroupId: videoGroupId } })">
                    <h4>Sayfa Modülleri</h4>
                    <b-card-text class="mt-1">
                        Konu içeriğindeki ders sayfa modülleri
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </section>
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="openPreviewPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <b-button variant="flat-success" @click="savePreview">
                    <feather-icon icon="SaveIcon" class="mr-50" />
                    <span class="align-middle">Kaydet</span>
                </b-button>
                <h5 class="mb-0">Önizle ve Düzenle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <draggable v-model="previewElement" tag="ul" class="list-group list-group-flush cursor-move">
                <b-list-group-item v-for="(item, index) in previewElement" :key="index" tag="li">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1 text-black">
                            {{item.name}}
                        </h5>
                        <small class="text-secondary">{{getDocumentType(item.type)}}</small>
                    </div>
                    <small class="text-secondary">Sürükleyerek öğrencilerin göreceği sıralamayı belirleyin</small>
                    <!-- <small class="text-secondary">{{item}}</small> -->
                </b-list-group-item>
            </draggable>
        </template>

    </b-sidebar>

    <!--Add Test Question --->
    <b-modal title="Hızlı Kurulum" size="lg" hide-footer no-close-on-backdrop v-model="isSetup">
        <fast-setup />
    </b-modal>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BButton,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BSidebar,
    BListGroupItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import FastSetup from "./PageComponent/FastSetup"
export default {
    components: {
        FastSetup,
        ToastificationContent,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BForm,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BSidebar,
        draggable,
        BListGroupItem,
        BButton
    },
    data() {
        return {
            videoGroupId: 0,
            openPreviewPanel: false,
            previewElement: [],
            isSetup: false
        }
    },
    methods: {
        getDocumentType(type) {
            switch (type) {
                case "Extension":
                    return "Eklenti";
                case "Video":
                    return "Video";
                case "Document":
                    return "Döküman";
                case "Test":
                    return "Test";
            }
        },
        savePreview() {
            var index = 0;
            this.previewElement.forEach(element => {
                element.viewOrder = index;
                index++;
            });
            var request = {
                "list": this.previewElement
            };
            this.$http.put("Teacher/UpdatePriview", request).then((data) => {
                    this.openPreviewPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Düzenleme Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    },
    mounted() {
        this.$root.$on('PreviewClick', () => {
            this.openPreviewPanel = true;
        });
        this.$root.$on('AddSetupClick', () => {
            this.isSetup = true;
        });
    },
    async created() {
        this.videoGroupId = this.$route.params.videoGroupId;
        var preview = await this.$http.get("Teacher/Priview/" + this.$route.params.videoGroupId);
        this.previewElement = preview.data.data;
    },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
